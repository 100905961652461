<template lang="pug">
    .flex
        template(v-if="$slots['total']")
            .text-sm
                span.mr-1.font-light Всего:
                slot(name="total")
</template>

<script>
export default {
    name: "FiltersBaseStatistics",
}
</script>