import { ICONS } from '@/configs/icons';

const getFileType = ({ file_type }) => {
    if (file_type.split('/')[0] === 'video') {
        return {type: 'video', icon: ICONS.file_video}
    }
    else if (file_type.split('/')[0] === 'image') {
        return {type: 'image', icon: ICONS.file_picture}
    }
    else if (file_type.split('/')[0] === 'text') {
        return {type: 'text', icon: ICONS.file_text}
    }
    else if (file_type === 'application/msword') {
        return {type: 'word', icon: ICONS.file_text}
    }
    else if (file_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return {type: 'word-oxml', icon: ICONS.file_text}
    }
    else if (file_type === 'application/pdf') {
        return {type: 'pdf', icon: ICONS.file_text}
    }
    else if (file_type === 'application/vnd.ms-excel') {
        return {type: 'xls', icon: ICONS.file_text}
    }
    else if (file_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return {type: 'xlsx', icon: ICONS.file_text}
    }
    else {
        return { type: 'other', icon: ICONS.file_empty }
    }
}

export { getFileType }